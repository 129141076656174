<template>
  <sl-dialog
    data-testid="confirmDialog"
    :class="`c-confirm-dialog ${className}`"
    no-header
    style="--width: initial"
    role="dialog"
    :open="isVisible"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @sl-request-close="e => closeDialog(e)"
  >
    <v-card class="dnl-px-8 dnl-py-6 dnl-flex dnl-flex-col dnl-gap-4">
      <LoadingOverlay v-if="isLoading" />

      <v-card-title class="headline !dnl-p-0 dnl-mb-2">{{
        dialogTitle.key ? $t(dialogTitle.key, dialogTitle.params) : $t(dialogTitle)
      }}</v-card-title>
      <v-card-text v-if="$slots.default">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="dnl-flex dnl-items-center dnl-justify-center dnl-gap-3 !dnl-p-0">
        <BtnMain
          v-for="(button, index) in dialogButtons"
          :key="index"
          rounded
          :class="button.className"
          :data-testid="button.testId || button.action"
          :btnText="button.text"
          :btnColor="button.color"
          :isLoading="button.showLoading && isLoading"
          @click="$emit(button.action)"
        ></BtnMain>
      </v-card-actions>
    </v-card>
  </sl-dialog>
</template>

<script lang="ts">
  import Vue from 'vue';
  import BtnMain from '@/components/Shared/Buttons/BtnMain.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';

  export default Vue.extend({
    name: 'ConfirmDialog',
    components: { BtnMain, LoadingOverlay },
    props: {
      className: {
        type: String,
        default: '',
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      isLoading: {
        type: Boolean,
      },
      dialogTitle: {
        type: [String, Object],
        required: true,
      },
      dialogButtons: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      closeDialog(e?: any) {
        if (e) e.preventDefault();
        this.$emit('close');
      },
    },
  });
</script>

<style lang="scss" scoped>
  sl-dialog::part(panel) {
    max-width: 69rem;
  }

  sl-dialog::part(body) {
    padding: 0;
    border-radius: 1em;
  }
</style>

<style lang="scss">
  .c-confirm-dialog {
    .v-card {
      &__title.headline {
        font-size: var(--font-size-lg) !important;
        line-height: var(--leading-8);
        word-break: break-word;
        font-weight: 500;
      }

      & .subtitle-1 {
        font-size: var(--font-size-base) !important;
        line-height: var(--leading-6) !important;
      }
    }
  }
</style>
